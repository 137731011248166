@import "defaults";

.margin-page-90 {
  @include tablet {
    margin: 90px 0 0 0;
  }

  @include desktop {
    margin: 90px 0 0 -585px !important;
  }
}

div {
  .um-report-container {
    box-shadow: 0 0 10px rgba(51, 51, 51, 0.15);

    + div {
      margin-bottom: 40px;
    }
  }
}

.um-report-container {
  width: calc(100% - 20px);
  box-shadow: 0 10px 30px 0 rgba(0, 44, 199, 0.22);
  display: inline-block;
  height: auto;
  margin: 20px 10px 40px 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  @include max-419 {
    margin: 20px 0px 40px 0px;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @include tablet {
    width: calc(100% - 40px);
    margin: 20px 20px 40px 20px;
  }

  @include desktop {
    width: calc(100% - 40px);
    margin: 20px 20px 40px 20px;
  }
}

.rotate {
  transform: rotate(180deg);
}

.report-details-content-btn-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.um-rectangle-l {
  background-color: $white;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.6);
  border-color: gray;
  width: 230px;
  text-align: center;
  color: $grey;
  line-height: 22px;
  height: 46px;
  font-weight: 400;
  font-family: $vfBold;
  display: table;
  position: relative;
  cursor: pointer;

  @include tablet {
    width: auto;
    min-width: 238px;
    float: left;
    margin: 0;
    left: 0;
  }
}

.print-report {
  left: 50%;
  margin: 20px 0 20px -126px;

  @include tablet {
    margin: 20px 0 20px -112px;
  }
}

.um-rectangle-text {
  font-size: 16px;
  font-weight: 400;
  font-family: $vfBold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center !important;
  color: rgb(51, 51, 51);
  display: table-cell;
  vertical-align: middle;
}

.um-report-block-footer {
  width: calc(100% - 40px);
  margin: 10px 20px 10px 20px;
  float: left;
  height: auto;
  position: relative;
  display: none;

  label {
    display: inline-block;
    font-size: 16px;
    width: calc(100% - 65px);
    padding: 20px 0 0 20px;
    line-height: 20px;
  }

  span {
    font-size: 14px;
    display: inline-block;
  }

  @include tablet {
    width: calc(50% - 0px);
    margin: 0px;
    // height: 200px;
    display: inline-block;

    label {
      width: calc(100% - 100px);
      padding: 20px;
    }

    span {
      margin-top: 2px;
    }
  }

  @include tablet-pt {
    width: calc(100% - 40px);
    margin: 10px 20px 10px 20px;
    float: left;
    height: auto;
    position: relative;
    display: inline-block;

    label {
      display: inline-block;
      font-size: 16px;
      width: calc(100% - 65px);
      padding: 20px 0 0 20px;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      display: inline-block;
    }
  }
}

.um-report-block-footer-content {
  display: inline-block;
  font-size: 16px;
  width: calc(100% - 20px);
  padding: 10px 10px 0 10px;

  @include tablet {
    width: calc(100% - 40px);
    padding: 10px 20px 0 20px;
  }

  @include desktop {
    width: calc(100% - 40px);
    padding: 10px 20px 0 20px;
  }
}

.bold {
  font-family: $vfBold;
  font-weight: 400;
  text-decoration: none;
  color: $grey;
  font-size: 16px;

  @include tablet {
    font-size: 20px;
  }
}

.nomobile {
  @media (max-width: 768px) {
    display: none;
  }
}

.report-mobile {
  display: none;

  @include max-767 {
    display: block;
  }
}

.report-desktop {
  @include max-767 {
    display: none;
  }
}

.report-detail-widget-mobile {
  display: none;

  @include max-767 {
    display: inline-block;
    padding: 0;
  }
}
