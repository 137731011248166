@import "defaults";

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow-x: hidden !important;
  //overflow-y: scroll !important;
}

.swal2-content-p0 {
  padding: 0 !important;
}

.swal-footer {
  text-align: center;
}

.swal2-popup {
  border-radius: 10px !important;
}

.swal_info_title {
  font-family: 'VodafoneRgBd', sans-serif;
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: #333;
  margin: 0;
}

.swal_info_body {
  font-family: 'VodafoneRg', sans-serif;
  font-size: 16px;
  width: 100%;
  text-align: center;
  color: #333;
}

.swal_info_body_measurement {
  font-family: 'VodafoneRg', sans-serif;
  font-size: 16px;
  width: 100%;
  text-align: center;
  color: #333;
}

.swal_info_close_btn,
.swal_info_cancel_btn,
.swal_error_close_btn,
.swal_info_close_btn_right {
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  background-color: transparent;
  border-radius: 25px;
  width: 200px;
  text-align: center;
  color: #333;
  line-height: 16px;
  display: table;
  position: relative;
  cursor: pointer;
  padding: 6px 10px 6px 10px;
  -webkit-tap-highlight-color: transparent;
  left: 50%;
  font-family: 'VodafoneRg', sans-serif;
  font-size: 16px;
  margin: 10px 0 0 -110px;
}

.swal_timeout_close_btn {
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  background-color: transparent;
  border-radius: 25px;
  width: 200px;
  text-align: center;
  color: #333;
  line-height: 16px;
  display: none;
  position: relative;
  cursor: pointer;
  padding: 6px 10px 6px 10px;
  -webkit-tap-highlight-color: transparent;
  left: 50%;
  font-family: 'VodafoneRg', sans-serif;
  font-size: 16px;
  margin: 10px 0 0 -110px;
}

@media (min-width: 768px) {
  .swal_info_close_btn_right {
    border-style: solid;
    border-width: 1px;
    border-color: #333;
    background-color: transparent;
    border-radius: 25px;
    width: 200px;
    text-align: center;
    color: #333;
    line-height: 16px;
    display: table;
    position: relative;
    cursor: pointer;
    padding: 6px 10px 6px 10px;
    -webkit-tap-highlight-color: transparent;
    font-family: 'VodafoneRg', sans-serif;
    font-size: 16px;
    float: right;
    margin: 0 10px 0 0;
    left: 0;
  }

  .swal_info_cancel_btn {
    border-style: solid;
    border-width: 1px;
    border-color: #333;
    background-color: transparent;
    border-radius: 25px;
    width: 200px;
    text-align: center;
    color: #333;
    line-height: 16px;
    display: table;
    position: relative;
    cursor: pointer;
    padding: 6px 10px 6px 10px;
    -webkit-tap-highlight-color: transparent;
    font-family: 'VodafoneRg', sans-serif;
    font-size: 16px;
    float: left;
    margin: 0 0 0 10px;
    left: 0;
  }
}
