@import "defaults";

.navbar {
  background-color: blue;
  // width: 100%;
  height: 50px;
  padding: 10px;
  // position: fixed;
  // top: 0;
  // z-index: 999;
  display: inline-block;
  border-bottom: 1px solid #eaeaea;

  @include tablet {
    height: 70px;
  }
}

.navbar-content {
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 1170px;
}

.navbar-logo {
  width: 48px;
  height: 48px;
  left: 30px;
  padding: 4px 10px;
  cursor: pointer;

  @include tablet {
    margin-top: 8px;
  }
}

.navbar-logo.disabled {
  opacity: 0.4;
}

.navbar-blocker {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #2d344b;
  z-index: 997;
}

.navbar-list {
  width: 100%;
  height: 0;
  padding: 0;
  position: fixed;
  top: 0;
  background-color: #f8f9fc;
  overflow-y: hidden;
  transition: 0.5s;
  text-align: center;
  list-style-type: none;
  z-index: 9999;
  margin: 71px auto 0 auto;
  max-width: 100%;

  @include tablet {
    margin: 90px auto 0 auto;
  }
}

.navbar-list-open {
  width: 100%;
  height: 229px;
  padding: 0 0 20px;
  position: fixed;
  top: 0;
  background-color: #f8f9fc;
  overflow-y: hidden;
  transition: 0.5s;
  text-align: center;
  list-style-type: none;
  z-index: 9999;
  margin: 71px auto 0 auto;
  max-width: 100%;

  @include tablet {
    margin: 90px auto 0 auto;
  }
}

.navbar-link {
  width: 165px;
  height: 209px;
  margin: 20px 15px 0 15px;
  padding: 0;
  text-decoration: none;
  transition: 0.3s;
  font-size: 16px;
  cursor: pointer;
  font-family: $vfRegular;
  border: none;
  display: inline-block;
  float: none;
  color: $grey;
  background-color: $white;
  text-align: center;

  &:hover {
    box-shadow: 0 0 10px rgba(51, 51, 51, 0.15);
    color: $grey;
  }

  img {
    width: 50px;
    padding: 4px 10px;
    margin: 30px;
  }

  label {
    font-size: 14px;
    line-height: 19px;
    font-family: $vfRegular;
    color: $grey;
    width: 80%;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    margin: 0 0 0 16px;
    float: left;
  }
}

@media (max-width: 1280px) {
  .navbar-list {
    height: 0;
    padding: 0;
    background-color: $white;
  }

  .navbar-list-open {
    height: 380px;
    padding: 0;
  }

  .navbar-link {
    width: calc(100% - 20px);
    height: 56px;
    float: left;
    margin: 0;
    padding: 10px;
    text-decoration: none;
    display: block;
    transition: 0.3s;
    font-size: 15px;
    cursor: pointer;
    font-family: $vfBold;
    border-top: 1px solid $grey;

    img {
      width: 50px;
      padding: 4px 10px;
      float: left;
      display: inline-block;
      margin: 0;
    }

    label {
      font-size: 14px;
      line-height: 19px;
      width: auto;
      display: inline-block;
      padding: 19px 0 0 0;
      margin: 0;
      cursor: pointer;
      float: left;
    }
  }
}

@media (max-width: ($breakpoint-tablet - 1px)) {
  .navbar-link {
    height: 38px;

    img {
      width: 30px;
      padding: 4px 10px;
    }

    label {
      font-size: 14px;
      line-height: 19px;
      padding: 10px 0 0 0;
    }
  }

  .navbar-list-open {
    height: 300px;
  }
}
