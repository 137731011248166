@import "defaults";

.result-headline {
  font-size: 26px;
  position: relative;
  padding: 20px 0 20px 0;
  width: calc(100% - 60px);
  display: inline-block;
  text-align: center;
  height: 30px;
  line-height: 26px;

  @include tablet {
    font-size: 32px;
    padding: 20px 20px 60px 30px;
    height: 20px;
    line-height: 60px;
  }

  @include max-414 {
    font-size: 20px;
    padding: 20px 0 10px 0;
    width: 100%;
  }

  p {
    padding: 0;
    margin: 0;
  }
}

.modem-measuring-details-block {
  width: calc(100% - 80px);
  height: auto;
  background-color: white;
  padding: 10px 20px 10px 20px;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.15);
  float: left;
  margin: 0 0 20px 20px;
  color: $grey;
  font-size: 16px;
  position: relative;
  text-align: left;

  hr {
    width: 100%;
    display: inline-block;
    border: 0;
    border-top: 1px solid #e8e9ea;
    margin: 10px 0;
  }

  @include tablet {
    width: calc(50% - 60px);
    min-height: 200px;
    padding: 30px 0 0 30px;
  }

  @include tablet-pt {
    width: calc(50% - 60px);
    min-height: 570px;
    padding: 30px 0 0 30px;
  }

  @include tablet-ls {
    width: calc(50% - 60px);
    height: 280px;
    padding: 30px 0 0 30px;
  }

  @include desktop {
    width: calc(50% - 60px);
    height: 320px;
  }
}

.report-boxtitle-icon {
  width: 36px;
  height: 36px;
  float: left;
  display: none;
  margin: 0 20px 0 0;

  @include tablet {
    display: inline-block;
    margin: 0 20px 0 0;
  }

  @include tablet-pt {
    margin: 0 4px 0 0;
  }

  @include tablet-ls {
    margin: 0 30px 0 0;
  }

  @include desktop {
    margin: 0 20px 0 0;
  }

  img {
    width: 32px;
    height: 32px;
  }
}

.modem-measuring-details-label {
  color: $grey;
  font-size: 16px;
  display: none;
  width: 80%;
  position: relative;
  float: left;
  height: auto;
  margin-bottom: 30px;

  @include tablet {
    font-size: 32px;
    width: auto;
    display: inline-block;
  }

  @include tablet-pt {
    font-size: 24px;
    width: 60%;
    margin-top: -3px;
  }

  @include tablet-ls {
    font-size: 28px;
    width: 75%;
    line-height: 30px;
    margin-top: -3px;
  }

  @include desktop {
    font-size: 32px;
    width: auto;
    margin-top: 0;
  }
}

.result-network-label {
  display: inline-block;
}

.modem-measuring-details-label-mobile {
  color: $grey;
  font-size: 16px;
  display: inline-block;
  width: 80%;
  position: relative;
  float: left;
  height: auto;
  margin-bottom: 30px;

  @include tablet {
    display: none;
  }
}

.label-bold {
  color: $grey;
  font-size: 16px;
  display: inline-block;
  width: 80%;
  position: relative;
  float: left;
  height: auto;
  margin-bottom: 30px;

  @include tablet {
    display: none;
  }
}

.modem-measuring-details-info {
  width: 24px;
  height: 24px;
  float: right;
  margin: 0 -5px 0 0;
  cursor: pointer;

  @include tablet {
    margin: 0 20px 0 0;
  }

  @include tablet-ls {
    margin: 0 20px 0 0;
  }

  @include desktop {
    margin: 0 20px 0 0;
  }
}

.report-tacho-container {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 200px;
  position: relative;
  width: 210px;
  height: 210px;
  display: none;
  opacity: 1;
  float: left;
  clear: both;
  margin: 20px 0 20px 0;
  left: 0;

  @include tablet-pt {
    opacity: 1;
    margin-top: -15px;
    left: 50%;
    margin-left: -121px;
    display: inline-block;
  }

  @include tablet-ls {
    margin: 0;
    width: 190px;
    height: 190px;
    display: inline-block;
  }

  @include desktop {
    width: 210px;
    height: 210px;
    margin: 20px 0 20px 0;
    display: inline-block;
    left: 0;
  }

  p {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 47.5%;
    left: 50%;
    font-size: 40px;
    transform: translate(-50%, -50%);
    line-height: 3rem;
    font-family: $vfBold;

    &:after {
      content: attr(data-content);
      position: absolute;
      font-size: 25%;
      bottom: 15px;
      left: 50%;
      transform: translate(-50%, 100%);
    }
  }
}

.modem-measuring-details-text {
  width: calc(100% - 20px);
  float: right;
  margin: 20px 20px 0 20px;
  text-align: left;

  @include tablet {
    width: calc(60% - 40px);
    float: right;
    margin: 20px;
  }

  @include tablet-pt {
    width: calc(100% - 20px);
    margin: 20px;
  }

  @include tablet-ls {
    width: calc(50% - 5px);
    margin: 20px 30px 0 0;
  }

  @include desktop {
    width: calc(60% - 40px);
    margin: 20px;
  }

  p {
    font-size: 18px;
    font-family: $vfBold;
    padding: 0;
    margin: 0;
    display: none;

    @include tablet {
      display: block;
    }
  }

  .info {
    font-size: 16px;
    display: none;

    @include tablet {
      display: block;
    }
  }

  .ping-info {
    font-size: 16px;
    display: block;
  }

  .number {
    width: 100%;
    font-size: 44px;
    color: #ccc;
    margin: 0;
    display: inline-block;
    padding: 10px 0 0 0;
    font-family: $vfBold;
    line-height: 30px;

    .report-active-original {
      color: $grey;
    }
  }

  .compare-info {
    position: relative;
    width: 100%;
    height: auto;
    display: inline-block;
    margin-top: 11px;
    font-size: 16px;
  }

  .active-blue {
    font-size: 35px;
    font-family: $vfBold;
  }
}

.report-chart-container {
  display: inline-block;
  width: calc(100% - 20px);
  position: relative;
  padding: 10px;
  margin: 40px 0 0 0;
}

.details-last {
  @include tablet-ls {
    min-height: 560px;
  }

  @include desktop {
    min-height: 560px;
  }
}

.um-tipps-link {
  left: 30px;
  margin-bottom: 16px;

  @include tablet {
    left: 140px;
  }
}

.speedtest-infos {
  font-size: 14px;
  width: 100%;
  display: inline-block;

  span {
    font-family: $vfBold;
  }

  #speedtest-id {
    text-decoration: none;
    border-bottom: 2px dotted $grey;
    cursor: pointer;
  }
}

.swal_info_body {
  font-family: $vfRegular;
  font-size: 16px;
  text-align: center;
  color: $grey;
}

.swal_info_close_btn {
  line-height: 50px;
  border-radius: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.6);
  background-color: white;
  border: 0;
  color: $grey;
  padding: 0;
  height: 46px;
  font-family: $vfBold;
  width: 200px;
  text-align: center;
  display: table;
  position: relative;
  cursor: pointer;
  left: 50%;
  margin: 10px 0 0 -100px;

  &:hover {
    background-color: #ebebeb;
  }

  &:active {
    box-shadow: 0 1px 3px rgba(50, 50, 50, 0.6) inset;
  }
}

// workaround to get rid of broken padding
.result-svg {
  .gaugeNew {
    @include result-tacho-custom-padding {
      padding: 0 !important;
    }
  }
}
