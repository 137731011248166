@import "./defaults";

.speedtest-elements-wrapper {
  display: inline-block;
  background-color: transparent;
  position: relative;
  width: 100%;
  margin: 0;
  text-align: center;
  overflow: hidden;

  @include tablet {
    max-width: 1170px;
    min-height: 600px;
  }
}

.speedtest-headline {
  font-size: 26px;
  position: relative;
  padding: 20px 0 20px 0;
  width: calc(100% - 60px);
  display: inline-block;
  text-align: center;
  height: 30px;
  line-height: 26px;

  @include tablet {
    font-size: 32px;
    padding: 20px 20px 60px 30px;
    height: 20px;
    line-height: 60px;
  }

  @include max-414 {
    font-size: 20px;
    padding: 20px 0 10px 0;
    width: 100%;
  }

  p {
    padding: 0;
    margin: 0;
  }
}

.speedtest-elements-container {
  margin: -10px 0 0 0;
  width: calc(100% - 20px);
  display: inline-block;
  position: relative;

  @include tablet {
    margin-bottom: 0;
    width: calc(100% - 40px);
  }

  @include desktop {
    margin: 0;
    width: calc(100% - 40px);
    display: inline-block;
    position: relative;
    //height: 490px;
  }
}

.speedtest-card-item {
  width: calc(100% - 20px);
  height: 32px;
  background-color: white;
  padding: 10px;
  float: left;
  border-radius: 6px;
  display: inline-block;
  overflow: hidden;
  position: relative;
  margin: 10px 0 0;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.15);
  
  @include tablet {
    width: calc(33.33% - 27px);
    height: 520px;
    background-color: rgba(255, 255, 255, 0.16);
    margin: 0 10px 0 0;

    &:last-child {
      margin: 0;
    }
  }

  @include tablet-pt {
    width: calc(33.33% - 27px);
    margin: 0 10px 0 0;
    height: 500px;
  }

  @include desktop {
    width: calc(33.33% - 40px);
    height: auto;
    background-color: rgba(255, 255, 255, 0.16);
    margin: 0 30px 0 0;
    min-height: 430px;
  }
}

.speedtest-card-title {
  text-align: left;
  display: inline-block;
  padding: 5px 10px 10px 10px;
  width: calc(100% - 20px);
  height: 37px;
  font-size: 20px;
  color: $white;
  font-family: $vfBold;
  vertical-align: top;

  @include tablet {
    text-align: center;
    font-weight: 400;
    padding: 20px 10px 0 10px;
    font-size: 32px;
    font-family: $vfRegular;
  }
}

.speedtest-card-title-wrapper {
  position: relative;
  display: inline-block;
  width: auto;
}

.speedtest-card-title-icon {
  width: 36px;
  height: 36px;
  float: left;
  display: none;
  margin: 0;

  img {
    width: 32px;
    height: 32px;
  }
}

.speedtest-card-title-label {
  color: $grey;
  float: left;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  font-family: $vfRegular;
}

.speedtest-gauge-container-inactive {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1) !important;
}

.speedtest-gauge-container {
  position: relative;
  width: 212px;
  height: 212px;
  display: inline-block;
  opacity: 1;
  margin-top: 20px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 200px;

  @include tablet-ls {
    opacity: 1;
    margin-top: 0;
  }

  @include tablet {
    width: 210px;
    height: 210px;
    opacity: 1;
    margin-top: 20px;
    background-color: white;
    border-radius: 200px;
    box-shadow: none;
  }

  @include desktop {
    width: 210px;
    height: 210px;
    opacity: 1;
    margin-top: 20px;
    background-color: white;
    border-radius: 200px;
    box-shadow: none;
  }
}

.zoom-in-from-left,
.zoom-in-from-right,
.zoom-in {
  @include tablet {
    height: 420px;
  }

  .speedtest-card-title {
    .speedtest-card-title-icon {
      @include tablet {
        width: 30px;
        height: 30px;
        display: inline-block;
      }

      @include desktop {
        margin: 0 10px 0 0;
        width: 30px;
        height: 30px;
      }
    }
  }

  .speedtest-gauge-container {
    p {
      font-size: 45px;
    }

    @include tablet {
      position: relative;
      width: 210px;
      height: 210px;
      display: inline-block;
      margin-top: 20px;
      background-color: white;
      border-radius: 200px;
      box-shadow: none;
    }

    @include tablet-pt {
      transform: scale(0.8);
      left: 0;
    }

    @include desktop-1024-pt {
      //transform: scale(0.8);
      left: 0;
    }

    @include desktop {
      margin-top: 0;
    }
  }
}

.speedtest-gauge-container {
  position: relative;
  width: 212px;
  height: 212px;
  display: inline-block;
  background-color: $white;
  border-radius: 200px;

  p {
    line-height: 3rem;
    color: $grey;
    font-family: $vfBold;
    position: absolute;
    top: 47.5%;
    left: 50%;
    font-size: 55px;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
  }
}

.speedtest-gauge-label {
  &:after {
    content: attr(data-content);
    position: absolute;
    font-size: 25%;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 100%);
  }
}

.measurement-speed-display {
  position: relative;
  width: calc(100% - 20px);
  display: inline-block;
  text-align: left;
  padding: 10px;
  margin: 0;
  word-break: break-word;

  @include tablet {
    padding: 4px;
  }

  p {
    font-size: 17px;
    font-family: $vfBold;
    padding: 0;
    min-height: 0;
    margin: 0;
  }

  .measurement-speed-display-number {
    width: auto;
    font-size: 40px;
    margin: 0;
    display: inline-block;
    padding: 10px 0 0 0;
    font-family: $vfBold;
    line-height: 25px;
    left: 50%;
    position: relative;
    transform: translate(-50%);

    .no-customer-label {
      font-size: 60px;
      display: inline-block;
      float: left;
    }

    .no-customer-unit {
      font-size: 16px;
      display: inline-block;
      float: left;
      width: auto;
      margin: 8px 0 0 0;
    }
  }

  .info-text {
    display: inline-block;
    height: 40px;
    font-size: 16px;
    color: $grey;
    font-weight: 400;
    margin-bottom: 0;

    @include tablet-ls {
      height: 60px;
    }

    @include desktop {
      height: 60px;
      font-size: 15px;
    }
  }

  .info-text-ping {
    display: inline-block;
    height: 40px;
    font-size: 16px;
    color: $grey;
    font-weight: 400;
    margin-bottom: 0;

    @include tablet-ls {
      height: 60px;
    }

    @include desktop {
      height: 60px;
      font-size: 15px;
    }
  }

  .speed-display-number {
    width: auto;
    font-size: 40px;
    margin: 0;
    display: inline-block;
    padding: 10px 0 0 0;
    font-family: $vfBold;
    line-height: 25px;
    left: 50%;
    position: relative;
    transform: translate(-50%);
  }

  @include tablet {
    width: calc(100% - 40px);
    padding: 10px 20px 10px 20px;

    .speed-display-number {
      width: 100%;
    }
  }

  @include desktop {
    width: calc(100% - 100px);

    p {
      padding: 10px 0 0 0;
    }
  }
}

div {
  &.server-pc {
    margin-top: 15px;

    @include tablet {
      margin-top: 5px;
    }
  }

  &.tacho-container {
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: 200px;
  }
}

.server-pc {
  width: 250px;
  height: auto;
  display: block;
  position: relative;
  margin: 22px 0 0 -125px;
  left: 50%;

  @include tablet {
    margin: 0 -125px;
  }

  @include desktop {
    width: 100%;
    left: 0;
    margin: -20px 0 0 0;
  }
}

.server-text,
.pc-text,
.mobile-text {
  width: 50%;
  height: 19px;
  font-size: 11.2px;
  text-align: center;
  float: left;
}

.pc-text {
  display: none;

  @include tablet {
    display: block;
  }
}

.mobile-text {
  @include tablet {
    display: none;
  }
}

.speedtest-server-pc-gif {
  display: none;

  @include tablet {
    display: inline-block;
    padding: 10px 0 0 0;

    img {
      filter: grayscale(1);
      width: 175px;
      height: auto;
    }
  }
}

.speedtest-server-mobile-gif {
  display: inline-block;
  padding: 10px 0 0 0;

  img {
    filter: grayscale(1);
    width: 175px;
    height: auto;
  }

  @include tablet {
    display: none;
  }
}

.messen-aktuell {
  width: 100%;
  height: auto;
  display: inline-block;
  font-size: 13.2px;
  line-height: 1.38;
  margin: 10px 0 10px 0;

  @include tablet-pt {
    width: calc(100% - 40px);
    font-size: 12px;
    text-align: center;
    margin: 20px;
  }
}

.zoom-in-from-left {
  height: auto;
  background-color: $white;
  transition: height 0.5s ease-out;
  padding: 0 10px 0 10px;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.15);

  @include tablet {
    transition: transform 0.4s;
    transform: scale(1.2);
    z-index: 10;
    left: 45px;
    padding: 20px 0;
    margin: 30px 0 0 0;
    height: 420px;
  }

  @include desktop {
    transition: transform 0.4s;
    transform: scale(1.2);
    z-index: 10;
    left: 45px;
    padding: 20px 0;
    margin: 30px 0 0 0;
    height: 370px;
    min-height: 350px;
  }

  .speedtest-card-title {
    text-align: center;
    display: inline-block;
    font-weight: 400;
    vertical-align: top;
    width: calc(100% - 20px);
    height: 37px;
    font-size: 32px;
    font-family: $vfBold;
    padding: 10px 10px 0;

    @include tablet {
      padding: 10px 10px 0 10px;
      font-size: 28px;
    }
  }

  .speedtest-card-title-label {
    width: 100%;
    font-family: $vfRegular;

    @include tablet {
      width: auto;
    }
  }
}

.zoom-in {
  height: auto;
  background-color: white;
  transition: transform 0.4s;
  padding: 0 10px 0 10px;

  @include tablet {
    transform: scale(1.2);
    z-index: 10;
    padding: 20px 0;
    margin: 30px 0 0 0;
    height: 420px;
  }

  @include desktop {
    transform: scale(1.2);
    z-index: 10;
    margin: 30px 0 0 0;
    height: 390px;
    min-height: 370px;
  }

  .speedtest-card-title {
    text-align: center;
    display: inline-block;
    font-weight: 400;
    vertical-align: top;
    width: calc(100% - 20px);
    height: 37px;
    font-size: 32px;
    font-family: $vfBold;
    padding: 10px 10px 0;

    @include tablet {
      padding: 10px 10px 0 10px;
      font-size: 28px;
    }
  }
}

.zoom-in-from-right {
  height: auto;
  background-color: white;
  transition: transform 0.4s;
  padding: 0 10px 0 10px;

  @include tablet {
    transform: scale(1.2);
    z-index: 10;
    right: 45px;
    padding: 20px 0;
    margin: 30px 0 0 0 !important;
    height: 420px;
  }

  @include desktop {
    transform: scale(1.2);
    z-index: 10;
    right: 45px;
    margin: 30px 0 0 0 !important;
    height: 390px;
    min-height: 370px;
  }

  .speedtest-card-title {
    text-align: center;
    display: inline-block;
    font-weight: 400;
    vertical-align: top;
    width: calc(100% - 20px);
    height: 37px;
    font-size: 32px;
    font-family: $vfBold;
    padding: 10px 10px 0;

    @include tablet {
      padding: 10px 10px 0 10px;
      font-size: 28px;
    }
  }
}

.zoom-out {
  transition: transform 0.4s;
  transform: scale(1);
  background-color: white;
  display: inline-block;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  padding: 0 10px 0 10px;

  .measurement-speed-display {
    top: 0;
    position: relative;
    display: inline-block;
    left: 0;

    p {
      width: 100%;
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  @include max-767 {
    padding: 0 10px 0 10px;
    height: auto;

    .speedtest-card-item {
      height: 110px;
    }

    .speedtest-card-title {
      display: none;
    }

    .speedtest-gauge-container {
      display: none;
    }

    .info-text {
      display: none;
    }
  }

  @include tablet {
    .speedtest-gauge-container {
      position: relative;
      width: 212px;
      height: 212px;
      display: inline-block;
      opacity: 1;
      margin-top: 25px;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    }

    .measurement-speed-display {
      p {
        width: 100%;
        display: inline-block;
        margin-bottom: 0;
      }
    }

    .measurement-speed-display-number {
      display: none;
    }
  }

  @include desktop {
    .speedtest-gauge-container {
      position: relative;
      width: 212px;
      height: 212px;
      display: inline-block;
      opacity: 1;
      margin-top: 10px;
      background-color: white;
      border-radius: 200px;
      box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.measurement-compare-info {
  display: none;

  @include tablet {
    position: relative;
    width: auto;
    height: auto;
    display: inline-block;
    font-size: 16px;
    float: left;
    margin: 0 6px 0 0;
  }
}

.active-green {
  font-size: 35px;
  font-family: $vfBold;
}

.measurement-compare-info-text {
  display: none;

  @include tablet {
    display: inline-block;
    float: left;
    height: auto;
    line-height: 18px;
    font-size: 15px;
    margin-top: 0;
  }

  @include tablet-pt {
    margin-top: 2px;
  }
}

.button-rectangle {
  width: 240px;
  height: auto;
  margin: 0 auto 30px auto;
  display: inline-block;
  position: relative;

  @include tablet {
    width: 510px;
    margin: 30px auto;
  }

  @include tablet-pt {
    width: 510px;
    margin: 40px 0 0 0;
  }
}

.btn-stage2 {
  margin: 0 auto 30px auto;
}

.button-rectangle-left {
  border-radius: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.6);
  height: 46px;
  font-family: $vfBold;
  border: 0;
  background-color: $grey;
  width: 230px;
  text-align: center;
  color: $grey;
  line-height: 22px;
  display: table;
  position: relative;
  cursor: pointer;
  padding: 0;

  @include tablet {
    width: auto;
    min-width: 238px;
    float: left;
    margin: 0;
    left: 0;
    line-height: 20px;
  }

  @include tablet-pt {
    width: auto;
    min-width: 238px;
    float: left;
    line-height: 20px;
    margin: 0;
    left: 0;
  }

  &.rectangle-grey {
    background-color: $grey;

    &:hover {
      background-color: #000;
    }
  }
}

.button-rectangle-right {
  border-radius: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.6);
  background-color: white;
  border: 0;
  color: $grey;
  line-height: 22px;
  padding: 0;
  height: 46px;
  font-family: $vfBold;
  width: 230px;
  text-align: center;
  display: table;
  position: relative;
  margin-top: 10px;
  cursor: pointer;

  @include tablet-pt {
    width: auto;
    min-width: 238px;
    margin: 0;
    left: 0;
    float: right;
  }

  @include tablet {
    width: auto;
    min-width: 238px;
    margin: 0;
    left: 0;
    float: right;
  }

  &:hover {
    background-color: #ebebeb;
  }
}

.button-rectangle-text {
  font-size: 16px;
  font-weight: 400;
  font-family: $vfBold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: table-cell;
  vertical-align: middle;

  &.white-label {
    color: $white;
  }
}

.lineal-slider {
  position: relative;
  width: 100%;
  padding: 40px 10px 0 0;
  box-sizing: border-box;
  height: 110px;
  display: inline-block;

  @include tablet {
    display: none;
  }
}

.gauge-lineal {
  width: 100%;
  box-sizing: border-box;

  rect {
    width: 8px;
    height: 64px;
    y: 22;
    transition: all 0.08s ease-in-out;
  }

  .current {
    height: 100%;
    fill: #f00 !important;
    y: 0;

    ~rect {
      fill: #ccc;
    }
  }
}

.gauge-lineal-label {
  position: absolute;
  top: -10px;
  right: 4px;
  font-size: 42px;
  color: rgb(51, 51, 51);
}

.gauge-lineal-units,
.gauge-lineal-min,
.gauge-lineal-max {
  color: #ccc;
}

.gauge-lineal-units {
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 14px;
  display: inline-block;
}

.gauge-lineal-content {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
}

.gauge-lineal-min {
  display: inline-block;
  position: absolute;
  left: 0;
  top: -20px;
  font-size: 14px;
}

.gauge-lineal-max {
  display: inline-block;
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 14px;
}

.gauge-lineal-percent {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 16px;
  color: $grey;

  & strong {
    color: rgb(51, 51, 51);
  }
}

.gauge-lineal-tarif {
  font-size: 16px;
  color: $grey;
}

.card-item-customer-finished {
  @include max-767 {
    height: 180px;
  }
}
