@font-face {
  font-family: 'vodafone-regular';
  src: url('./fonts/VodafoneRg.woff2');
}

@font-face {
  font-family: 'vodafone-bold';
  src: url('./fonts/vodafonergbd-webfont.woff2');
}

@font-face {
  font-family: 'vodafone-lite';
  src: url('./fonts/VodafoneLt.ttf');
}

@font-face {
  font-family: 'inter-bold';
  src: url('./fonts/Inter-Bold.ttf');
}

@font-face {
  font-family: 'inter-regular';
  src: url('./fonts/Inter-Regular.ttf');
}

$vfRegular: 'vodafone-regular';
$vfBold: 'vodafone-bold';

$white: #fff;
$black: #000;
$grey: #333;
$light-grey: #ccc;
$vf-red: #e60000;

$breakpoint-tablet: 768px;

// Devices
$screen-tablet: 768px;
$screen-desktop: 1280px;

// Custom breakpoints
$screen-desktop-index-carousel: 1305px;
$screen-max-375: 375px;
$screen-max-414: 414px;
$screen-max-419: 419px;
$screen-max-512: 512px;
$screen-600: 600px;
$screen-800: 800px;
$screen-min-854: 854px;
$screen-max-767: 767px;
$screen-max-768: 768px;
$screen-max-920: 920px;
$screen-max-992: 992px;
$screen-max-1024: 1024px;
$screen-max-1279: 1279px;
$screen-max-1280: 1280px;
$screen-1024: 1024px;

// landscape

@mixin result-tacho-custom-padding {
  @media (min-width: $screen-min-854) and (max-width: $screen-max-1279) and (orientation: landscape) {
    @content;
  }
}

// mixins
@mixin tablet {
  @media (min-width: $screen-tablet) {
    @content;
  }
}

@mixin tablet-ls {
  @media (min-width: $screen-tablet) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet-pt {
  @media (min-width: $screen-tablet) and (orientation: portrait) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $screen-desktop) {
    @content;
  }
}

@mixin desktop-carousel {
  @media (min-width: $screen-desktop-index-carousel) {
    @content;
  }
}

@mixin desktop-1024-pt {
  @media (min-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

@mixin max-375 {
  @media (max-width: $screen-max-375) {
    @content;
  }
}

@mixin min-414 {
  @media (min-width: $screen-max-414) {
    @content;
  }
}

@mixin max-414 {
  @media (max-width: $screen-max-414) {
    @content;
  }
}

@mixin max-419 {
  @media (max-width: $screen-max-419) {
    @content;
  }
}

@mixin max-512 {
  @media (max-width: $screen-max-512) {
    @content;
  }
}

@mixin max-600 {
  @media (max-width: $screen-600) {
    @content;
  }
}

@mixin min-600 {
  @media (min-width: $screen-600) {
    @content;
  }
}

@mixin max-767 {
  @media (max-width: $screen-max-767) {
    @content;
  }
}

@mixin max-800 {
  @media (max-width: $screen-800) {
    @content;
  }
}

@mixin max-768 {
  @media (max-width: $screen-max-768) {
    @content;
  }
}

@mixin min-800 {
  @media (min-width: $screen-800) {
    @content;
  }
}

@mixin max-920 {
  @media (max-width: $screen-max-920) {
    @content;
  }
}

@mixin min-1024 {
  @media (min-width: $screen-1024) {
    @content;
  }
}

@mixin max-1024 {
  @media (max-width: $screen-max-1024) {
    @content;
  }
}

@mixin max-1280 {
  @media (max-width: $screen-max-1280) {
    @content;
  }
}

@mixin screen-375-414 {
  @media (min-width: $screen-max-375) and (max-width: $screen-max-414) {
    @content;
  }
}

@mixin screen-414-767 {
  @media (min-width: ($screen-max-414 + 1px)) and (max-width: ($screen-max-767 - 1px)) {
    @content;
  }
}

@mixin screen-768-992 {
  @media (min-width: $screen-tablet) and (max-width: $screen-max-992) {
    @content;
  }
}

@mixin screen-max-992 {
  @media (min-width: $screen-max-992) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
