@import "defaults";

.um-tipp-head {
  background-image: linear-gradient(45deg, #bd0000 0%, #e80000 100%);
  width: 100%;
  height: 220px;
  text-align: center;
  object-fit: contain;
}

.um-tipp-icon {
  width: 96px;
  height: 96px;
  display: inline-block;
  position: relative;
  margin: 30px 0 0 0;

  img {
    width: 96px;
  }
}

.um-tipp-title {
  color: $white;
  width: calc(100% - 20px);
  height: auto;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  margin: auto;

  @include tablet {
    width: 100%;
    font-size: 32px;
  }
}

.um-tipp-menu {
  width: 324px;
  position: relative;
  display: inline-block;
  left: 50%;
  height: 56px;
  margin: -25px 0 0 -171px;

  @include tablet {
    height: 56px;
    margin: -25px auto;
    left: 0;
    display: block;
  }
}

.back-tipp {
  width: 48px;
  height: 48px;
  border-radius: 35.2px;
  box-shadow: 0 0 4px rgba(51, 51, 51, 0.3);
  float: left;
  cursor: pointer;
  margin: 0 60px;
  display: block;
  background-color: $white;
  &:hover {
    background-color: $light-grey;
  }
  &:active {
    box-shadow: none;
  } 
}

.um-overview {
  width: 68px;
  height: 48px;
  border-radius: 35.2px;
  box-shadow: 0 0 4px rgba(51, 51, 51, 0.3);
  background-color: $white;
  float: left;
  margin-right: 10px;
  cursor: pointer;
  left: 128px;
  position: absolute;
  &:hover {
    background-color: $light-grey;
  }
  &:active {
    box-shadow: none;
  }
}

.overview-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin: 18% auto auto;
}

.next-tipp {
  width: 48px;
  height: 48px;
  border-radius: 35.2px;
  box-shadow: 0 0 4px rgba(51, 51, 51, 0.3);
  background-color: $white;
  float: right;
  cursor: pointer;
  margin: 0 60px 0 0;
  &:hover {
    background-color: $light-grey;
  }
  &:active {
    box-shadow: none;
  } 
}

.next-tipp-right {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-top: 12px;
  margin-left: 12px;
  float: left;
  cursor: pointer;
}

.um-tipp-content {
  width: calc(100% - 20px);
  height: auto;
  font-size: 16px;
  line-height: 1.38;
  text-align: center;
  margin: 20px auto 20px auto;

  @include tablet {
    width: 748px;
  }
}

.icon-tipp-content {
  max-width: 100%;
  max-height: 360px;
  height: auto;
  padding: 20px;
  display: inline-block;
  text-align: center;
  margin: auto auto 0;

  img {
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 350px;
    object-fit: contain;

    &.small-img {
      width: 175px;
      margin-bottom: 20px;
    }
  }
}

.um-bro-content {
  width: 100%;
  height: auto;
  margin: 0% auto auto;
  padding-top: 32px;
  display: inline-block;

  @include tablet {
    padding-top: 52px;
    padding-bottom: 40px;
  }
}

.um-bro-content-title {
  width: 100%;
  height: auto;
  font-family: $vfBold;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: $white;
  margin: auto;
  padding-bottom: 25px;
}

.um-bro-content-text {
  width: calc(100% - 20px);
  height: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: $white;
  display: inline-block;
  margin-bottom: 20px;

  @include tablet {
    width: 555px;
  }
}

#common-mark-content-div li {
  text-align: start;
}

#common-mark-teaser-div li {
  text-align: start;
}

#common-mark-teaser-div img {
  width:80px;
}

.um-tipp-fertig {
  height: auto;
  background-color: $grey;
  padding-top: 20px;
  text-align: center;
}

.um-bro-button {
  border-radius: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.6);
  background-color: $white;
  border: 0;
  color: $grey;
  line-height: 22px;
  padding: 0;
  width: 263px;
  height: 46px;
  font-weight: 400;
  font-family: $vfBold;
  margin: 20px auto 20px auto;
  cursor: pointer;
}

.um-bro-content-button-text {
  font-family: $vfBold;
  font-size: 16px;
  text-align: center;
  color: $grey;
  font-weight: 400;
  height: 100%;
  line-height: 48px;
  cursor: pointer;

  p {
    padding: 0;
    margin: 0;

    input[type="checkbox"] {
      line-height: 2.1ex;

      +label {
        position: relative;
        overflow: hidden;
        cursor: pointer;
      }

      +label:before {
        vertical-align: -7px;
        content: '';
        display: inline-block;
        height: 1ex;
        width: 1ex;
        background-color: $white;
        margin-right: 0.5em;
        border: 1px solid $white;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        position: relative;
        cursor: pointer;
        zoom: 0.8;
      }

      &:checked + label:before {
        background-color: $white;
      }

      &:checked + label:after {
        content: '';
        position: absolute;
        width: 2.4ex;
        height: 1.2ex;
        background: rgba(0, 0, 0, 0);
        top: 0.5ex;
        left: 0.4ex;
        border: 5px solid #333;
        border-top: none;
        border-right: none;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        display: block;
        zoom: 0.8;
      }
    }

    input {
      padding: 0;
      width: inherit;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    label {
      color: $grey;
      font-family: $vfBold;
      font-weight: 400;
    }
  }
}
