@import "defaults";

.index-carousel-container {
  width: 320px;
  height: 280px;
  text-align: center;
  overflow: hidden;
  left: 50%;
  position: relative;
  margin-left: -160px;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.index-carousel {
  text-align: center;
  padding: 0;
  height: 100%;
  max-width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  .slides {
    width: 400%;
    left: 0;
    padding-left: 0;
    padding-top: 1em;
    overflow: hidden;
    list-style: none;
    position: relative;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    -o-transition: transform 0.5s;
    transition: transform 0.5s;
    -webkit-tap-highlight-color: transparent;
  }

  .slidesNavigation {
    display: inline-block;
    list-style: none;
    text-align: center;
    bottom: 20px;
    position: relative;
    width: auto;
    -webkit-tap-highlight-color: transparent;

    label {
      float: left;
      margin: 6px;
      display: block;
      height: 12px;
      width: 12px;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      border: solid 2px #ddd;
      font-size: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  input {
    display: none;
  }
}

#index-carousel_item1:checked ~ .slides {
  transform: translateX(0%);

  @include tablet {
    transform: translateX(0%);
  }
}

#index-carousel_item2:checked ~ .slides {
  transform: translateX(-25%);

  @include tablet {
    transform: translateX(0%);
  }
}

#index-carousel_item3:checked ~ .slides {
  transform: translateX(-50%);

  @include tablet {
    transform: translateX(0%);
  }
}

#index-carousel_item4:checked ~ .slides {
  transform: translateX(-75%);

  @include tablet {
    transform: translateX(0%);
  }
}

.index-carousel #index-carousel_item1:checked ~ .slidesNavigation label#dotindex-carousel_item1,
.index-carousel #index-carousel_item2:checked ~ .slidesNavigation label#dotindex-carousel_item2,
.index-carousel #index-carousel_item3:checked ~ .slidesNavigation label#dotindex-carousel_item3,
.index-carousel #index-carousel_item4:checked ~ .slidesNavigation label#dotindex-carousel_item4 {
  background: #888;
  border: solid 2px #888;
}

.index-carousel_item {
  width: 278px;
  height: 180px;
  border-radius: 6px;
  border: solid 1px #ccc;
  background-color: transparent;
  float: left;
  margin: 10px;
  padding: 10px;
  display: inline-block;
}

.index-carousel_item_svg {
  margin: 15px auto auto;

  img {
    width: 60px;
    height: 60px;
  }
}

.index-carousel_item_content {
  width: 100%;
  height: auto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: $grey;
  font-family: $vfRegular, sans-serif;
}

.index-carousel_item_nav_container {
  width: 100%;
  height: 20px;
  padding: 10px;
  margin: 10px;
  text-align: center;
}

.index-carousel_item_nav {
  width: 12px;
  height: 12px;
  background-color: #ddd;
  border-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 768px) {
  .index-carousel .slidesNavigation {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 990px) {
  .index-carousel_item {
    width: 140px;
    height: 180px;
  }

  .index-carousel_item_content {
    font-size: 14px;
  }
}

@media (min-width: 991px) {
  .index-carousel_item {
    width: 195px;
    height: 180px;
    border-radius: 6px;
    border: solid 1px $light-grey;
    background-color: transparent;
    float: left;
    margin: 10px;
    padding: 10px;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .index-carousel-container {
    width: 100%;
    height: 256px;
    left: 0;
    margin-left: 0;
    display: inline-block;
  }

  .index-carousel {
    text-align: center;
    padding: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  .index-carousel .slides {
    width: auto;
    display: inherit;
  }
}
