@import "defaults";

.um-tarif-text {
  width: 89px;
  height: 32px;
  font-size: 16px;
  line-height: 1.33;
  text-align: center;
  color: white;
  margin: 14px 0 0 4px;
  float: left;

  @include tablet {
    font-size: 19px;
    margin: 13px 0 0 30px;
  }
}

.um-tarif-icon-download,
.um-tarif-icon-upload {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 8px 4px;
  float: left;
  display: none;

  @include tablet {
    display: inline-block;
  }

  img {
    width: 28px;
    height: 28px;
  }
}

.um-tarif-text-download-value,
.um-tarif-text-upload-value {
  font-size: 32px;
  color: white;
  float: left;
  margin: 6px 0 0 0;

  @include tablet {
    font-size: 36px;
    margin: 3px 0 0;
  }
}

.um-tarif-text-download,
.um-tarif-text-upload {
  width: 82px;
  height: 32px;
  font-size: 12px;
  line-height: 1.14;
  color: white;
  margin: auto;
  padding: 10px 0 0 3px;
  float: left;
  text-align: left;

  @include tablet {
    font-size: 14px;
    padding: 8px 0 0 3px;
  }
}

.download-upload-bold {
  font-family: $vfBold;
}
