@import "defaults";
@import "indexaccordion.scss";
@import "speedtest.scss";
@import "preloader";
@import "indexcardcarousel";
@import "navbar";
@import "measurementoverlay";
@import "report";
@import "tipps";
@import "result";
@import "singletipp";
@import "speedtest-modem";
@import "tariffinfo";
@import "prepare";

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $vfRegular;
  color: $grey;
}

main {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  margin: 0;
  flex: 1;
  min-height: calc(100% - 60px);
}

.dot {
  width: 200px;
  height: 200px;
  margin-top: -50px;
  box-shadow: 0 0 0 16px rgba(255, 255, 255, 0.1), 0 0 20px rgba(0, 0, 0, 0.1);
  background-size: 40px;
  background-color: white;
  cursor: pointer;
  border-radius: 50%;
  display: inline-block;
  animation-fill-mode: both;

  img {
    width: 40px;
    margin-top: 35%;
  }

  .text-start {
    margin: 0;
    font-family: $vfRegular;
    color: $grey;
    font-size: 24px;
  }

  @include tablet {
    margin-top: -124px;
    box-shadow: 0 0 0 22px rgba(255, 255, 255, 0.1), 0 0 31px rgba(0, 0, 0, 0.1),
      0 0 31px rgba(0, 0, 0, 0.1);
    height: 248.6px;
    width: 248.6px;
    background-size: 50px;

    img {
      width: 50px;
    }

    .text-start {
      font-size: 32px;
    }
  }

  @include desktop {
    img {
      height: 54px;
      width: 56px;
    }
  }
}

.img-house-index {
  animation-fill-mode: both;
}

.main-content {
  position: relative;
  padding-top: 0;
  display: block;
  text-align: center;
}

// Buttons
.btn-wrapper {
  width: 240px;
  height: auto;
  margin: 0 auto 30px auto;
  display: inline-block;
  position: relative;
}

@media (min-width: 768px) {
  .btn-wrapper {
    width: 510px;
    height: auto;
    margin: 40px 0 0 0;
    display: inline-block;
    position: relative;
  }

  .vf-button-left {
    float: left;
  }

  .vf-button-right {
    float: right;
  }

  .um-title-content,
  .um-title-text {
    position: relative;
    font-family: $vfRegular;
    color: $grey;
    display: block;
    font-size: 32px;
    line-height: 1;
    text-align: center;
    margin: 70px auto 0 auto;
    max-width: 785px;
  }

  .um-title-text {
    font-size: 14px;
    line-height: 1.38;
    font-weight: 400;
    margin-top: 40px;
  }
}

.um-title-content-small {
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #333;
  margin: 40px 20px 0 20px;
  font-family: $vfRegular;
  width: calc(100% - 40px);
  display: inline-block;
}

@media (min-width: 768px) and (orientation: portrait) {
  .btn-wrapper {
    height: auto;
    margin: 40px 0 0 0;
    display: inline-block;
    position: relative;
  }
}

.vf-button-left,
.vf-button-right {
  border-radius: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.6);
  background-color: $white;
  border: 0;
  color: $grey;
  line-height: 22px;
  padding: 0;
  height: 46px;
  font-weight: 400;
  font-family: $vfBold;
  width: auto;
  min-width: 238px;
  text-align: center;
  display: table;
  position: relative;
  margin: 10px 0 0 0;
  left: 0;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: $light-grey;
  }

  .btn-text {
    display: table-cell;
    vertical-align: middle;
  }
}

.react-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  box-shadow: 0 1px 3px rgb(50 50 50 / 60%);
  height: 46px;
  font-family: vodafone-bold;
  border: 0;
  width: 230px;
  text-align: center;
  color: $grey;
  cursor: pointer;
  padding: 0;
  background-color: $white;
  &:hover {
    background-color: $light-grey;
  }
  &:active {
    box-shadow: none;
  }
}

.react-button-primary {
  color: $white;
  background-color: $grey;
  &:hover {
    background-color: $black;
  }
}

.um-content-wrapper {
  display: block;
  position: relative;
  max-width: 100%;
  text-align: center;
}

.um-title-content,
.um-title-text {
  position: relative;
  font-family: $vfRegular;
  color: $grey;
  display: block;
  font-size: 32px;
  line-height: 1;
  text-align: center;
  margin: 70px auto 0 auto;
  max-width: 785px;
}

.um-title-text {
  font-size: 14px;
  line-height: 1.38;
  font-weight: 400;
  margin-top: 40px;

  @include desktop {
    margin-top: 20px;
  }
}

// Index Cards
.index-card-container {
  width: 100%;
  height: 256px;
  left: 0;
  margin-left: 0;
  display: block;
  position: relative;
  padding-inline: 0;
  text-align: center;

  .index-card-group {
    left: 0;
    padding-left: 0;
    padding-top: 1em;
    overflow: hidden;
    list-style: none;
    display: inline-block;
    position: relative;

    .index-card-item {
      width: 195px;
      height: 180px;
      border-radius: 6px;
      border: solid 1px $light-grey;
      background-color: transparent;
      float: left;
      margin: 10px;
      padding: 10px;
      /* display: inline-block; */
    }

    .index-card-item-svg {
      margin: 15px auto auto auto;

      > img {
        width: 60px;
        height: 60px;
      }
    }

    .index-card-item-content {
      font-family: $vfRegular;
      color: $grey;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.38;
    }
  }
}

@media (min-width: 992px) {
  .navbar-toggler {
    display: inline;
    margin-right: 0;
  }
}

@media (max-width: ($breakpoint-tablet - 1px)) {
  .dot-wrapper {
    text-align: center;
    z-index: 9999;
    margin-top: -174px;
    margin-bottom: 40px;
  }

  .start-mobile-hidden {
    display: none !important;
  }
}

@media (min-width: $breakpoint-tablet) {
  .index-slider {
    height: 385px;
  }

  .um-title-content-small {
    font-size: 24px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    margin: 60px 20px 0 20px;
    font-family: $vfRegular;
    width: calc(100% - 40px);
    display: inline-block;
  }

  .dot-wrapper {
    text-align: center;
    z-index: 9999;
    margin-bottom: 20px;
  }
}

.hidden-scrollbar {
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
