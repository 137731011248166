.preloader-dual-ring {
  display: inline-block;
  width: 32px;
  height: 32px;
}

.preloader-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  //margin-bottom: 20px;
  border-radius: 50%;
  border: 2px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: preloader-dual-ring 1.2s linear infinite;
}

@keyframes preloader-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
