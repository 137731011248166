

.start_speedtest_design {
  height: 290px;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.1), 0 0 31px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=13)";
  filter: alpha(opacity=13);
  border-radius: 50%;
  position: relative;
  width: 290px;
  margin: 20px 0 20px 0;
  -webkit-box-shadow: 0 0 22px rgba(0, 0, 0, 0.1), 0 0 31px rgba(0, 0, 0, 0.1);
  display: inline-block;
  top: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

div.start_speedtest_design {
  box-shadow: none;
  margin-top: 10%;
}


@media (min-width: 768px) {
  div.start_speedtest_design {
    margin-top: 200px;
  }
}

.init_tacho_container {
  position: absolute;
  width: 248.6px;
  top: 0;
  left: 50%;
  display: none;
  margin-left: -124.3px;
  -webkit-tap-highlight-color: transparent;
}
.init_tacho_container .gauge path:first-child {
  opacity: 1 !important;
  display: block !important;
}
.init_tacho_container p {
  position: absolute;
  width: 100%;
  height: 40px;
  top: 35%;
  left: 0;
  margin-top: 20px;
  text-align: center;
  font-family: "VodafoneRgBd", sans-serif;
  font-size: 20px;
  -webkit-tap-highlight-color: transparent;
  color: #333;
}


.speedtest_start_design {
  height: 248.6px;
  box-shadow: 0 0 31px rgba(0, 0, 0, 0.1), 0 0 31px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  width: 248.6px;
  top: 21px;
  left: 0;
  right: 21px;
  bottom: 21px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
}


.speedtest_start_design {
  margin: 0;
}
.speedtest_start_design img {
  width: 50px;
}