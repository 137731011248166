@import "defaults";

.modem-measuring-content {
  margin: 20px;

  @include tablet {
    margin: 40px auto 0;
    max-width: 1170px;
  }

  .modem-measuring-content-title-lg {
    display: none;

    @include tablet {
      display: block;
      font-size: 32px;
      font-family: $vfRegular;
      width: calc(100% - 20px);
    }
  }

  .modem-measuring-content-title-sm {
    display: block;
    font-size: 16px;
    line-height: 22px;
    font-family: $vfBold;

    @include tablet {
      display: none;
    }
  }

  .info {
    font-size: 16px;
    line-height: 22px;

    @include tablet {
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.speedtest-modem-graphic-lg {
  display: none;

  @include tablet {
    display: block;
  }
}

.speedtest-modem-graphic-sm {
  display: block;
  //max-width: calc(100% - 40px);
  //margin: auto;

  @include tablet {
    display: none;
  }
}

.speedtest-modem-graphic-speed-text {
  font-size: 46px;
  font-family: $vfBold;
}

.speedtest-modem-graphic-caption-text {
  font-size: 20px;
  font-family: $vfBold;
}

.speedtest-modem-graphic-small-subheader-text {
  font-size: 16px;
  font-family: $vfBold;
}

.speedtest-modem-graphic-subheader-text {
  font-size: 24px;
  font-family: $vfBold;
}

.speedtest-modem-graphic-unit-text {
  font-size: 23px;
  font-family: $vfBold;
}

.speedtest-modem-graphic-mobile-value-text {
  font-size: 24px;
  font-family: $vfBold;
}

.speedtest-modem-graphic-mobile-unit-text {
  font-size: 14px;
  font-family: $vfBold;
}
