@import './styles/defaults';

*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.accordion {
  max-width: 930px;
  margin: 30px auto 0px;
}

.accordion__item {
  background-color: white;
  font-family: $vfRegular;
  color: $grey;
  border: 1px solid #ccc;
  border-bottom: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  margin: 0;

  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 1px solid #ccc;
  }
}

.accordion__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px;
  cursor: pointer;
  position: relative;
}

.accordion-active {
  font-family: $vfBold;
  box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.6);
}

.accordion__icon {
  content: url("/img/arrow-down.svg");
}

.accordion__icon--turn {
  transform: rotate(180deg);
}

.accordion__details > p {
  padding: var(--padding);
}

.bold-big {
  font-family: $vfBold;
  font-size: 16px;
  display: block;
  margin: 20px 0 6px 0;
}
