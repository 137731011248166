@import "defaults";

.um-overview-tipp-title {
  width: auto;
  height: auto;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: $grey;
  margin: 25px 0 20px 0;

  @include tablet {
    font-size: 32px;
    line-height: 32px;
    margin: 40px auto 65px auto;
  }

  @include desktop {
    font-size: 32px;
    line-height: 32px;
    margin: 60px auto 65px auto;
  }
}

.main {
  font-family: Arial, sans-serif;
  width: 100%;
  height: auto;
  display: inline-block;
  margin: 0 auto;
  overflow: hidden;

  @include tablet {
    height: 470px;
  }
}

.content-slider {
  background-image: linear-gradient(45deg, #bd0000 0%, #e80000 100%);

  @include tablet {
    height: 470px;
  }
}

.icon-slider {
  display: inline-block;
  margin-top: 0;

  img {
    width: 44px;
    height: auto;
    display: inline-block !important;
    margin-top: 10px;
  }

  @include tablet {
    margin-top: 120px;

    img {
      width: 96px;
      margin-top: 0;
    }
  }
}

.slick-slide {
  color: #fff;
  position: relative;
  text-align: center;
  width: 100%;
  display: inline-block;
  border: 0 !important;

  @include tablet {
    height: 470px !important;
  }
}

.slick-track {
  height: 0 !important;
}

.text-slider {
  width: calc(100% - 40px);
  height: 80px;
  font-family: $vfRegular;
  font-size: 19px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.27;
  letter-spacing: normal;
  color: #fff;
  margin: 10px auto;
}

.slider-btn {
  background-color: #fff;
  border-radius: 0;
  width: 204px;
  text-align: center;
  color: $grey;
  float: left;
  line-height: 22px;
  display: table;
  position: relative;
  margin: 0 0 20px -100px;
  left: 50%;
  cursor: pointer;
  padding: 0;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.6);
  border: 0;
  height: 46px;
  font-weight: 400;
  font-family: $vfBold;

  &:hover {
    background-color: #ebebeb;
  }

  @include tablet {
    margin-left: -100px;
  }

  @include desktop {
    margin-left: -112px;
  }

  @include screen-768-992 {
    width: 140px;
    margin-left: -70px;
  }
}

.slider-btn-text {
  font-family: $vfBold;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $grey;
  display: table-cell;
  vertical-align: middle;
}

.slider-nav {
  width: 256px;
  height: 50px;
  display: inline-block;
  text-align: center;
  margin: 0px 0 0 -128px;
  left: 50%;
  position: relative;

  .slider-nav-item-back {
    box-shadow: 0 0 4px rgba(51, 51, 51, 0.3);
    width: 48px;
    height: 48px;
    border-radius: 35.2px;
    background-color: $white;
    float: left;
    transform: translateY(-50%);
    &:hover {
      background-color: $light-grey;
    }
    &:active {
      box-shadow: none;
    } 
  }

  .slider-nav-item-next {
    box-shadow: 0 0 4px rgba(51, 51, 51, 0.3);
    width: 48px;
    height: 48px;
    border-radius: 35.2px;
    background-color: $white;
    float: right;
    transform: translateY(-50%);
    &:hover {
      background-color: $light-grey;
    }
    &:active {
      box-shadow: none;
    } 
  }
}

.back-tipp-left {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 10px;
  margin-top: 12px;
  float: left;
  cursor: pointer;
}

.nav-info {
  box-shadow: 0 0 4px rgba(51, 51, 51, 0.3);
  width: 140px;
  height: 48px;
  border-radius: 25px;
  background-color: $white;
  float: left;
  margin: 0 10px 0 58px;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
}

.nav-info-text {
  width: 100%;
  height: auto;
  font-family: $vfRegular;
  color: $grey;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: 48px;
}

.next-tipp-right {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-top: 12px;
  margin-left: 12px;
  float: left;
  cursor: pointer;
}